// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-cgu-js": () => import("./../../../src/pages/cgu.js" /* webpackChunkName: "component---src-pages-cgu-js" */),
  "component---src-pages-evenement-js": () => import("./../../../src/pages/evenement.js" /* webpackChunkName: "component---src-pages-evenement-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mentions-legales-js": () => import("./../../../src/pages/mentions-legales.js" /* webpackChunkName: "component---src-pages-mentions-legales-js" */),
  "component---src-pages-profil-js": () => import("./../../../src/pages/profil.js" /* webpackChunkName: "component---src-pages-profil-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-sign-in-1-js": () => import("./../../../src/pages/SignIn1.js" /* webpackChunkName: "component---src-pages-sign-in-1-js" */),
  "component---src-pages-sign-in-js": () => import("./../../../src/pages/SignIn.js" /* webpackChunkName: "component---src-pages-sign-in-js" */),
  "component---src-pages-sign-up-js": () => import("./../../../src/pages/SignUp.js" /* webpackChunkName: "component---src-pages-sign-up-js" */),
  "component---src-pages-wedding-js": () => import("./../../../src/pages/wedding.js" /* webpackChunkName: "component---src-pages-wedding-js" */)
}

